// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-legal-jsx": () => import("/builds/tridimensionalweb/reutilizable/src/templates/PageLegal.jsx" /* webpackChunkName: "component---src-templates-page-legal-jsx" */),
  "component---src-templates-product-category-jsx": () => import("/builds/tridimensionalweb/reutilizable/src/templates/ProductCategory.jsx" /* webpackChunkName: "component---src-templates-product-category-jsx" */),
  "component---src-pages-index-jsx": () => import("/builds/tridimensionalweb/reutilizable/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-404-js": () => import("/builds/tridimensionalweb/reutilizable/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

