import { createMuiTheme } from '@material-ui/core/styles'

const palette = {
  primary: {
    main: '#077E84',
  },
  secondary: {
    main: '#d3c1b1',
  },
  background: {
    default: '#fff',
  },
}

const themeName = 'Reutilizable'

export default createMuiTheme({ palette, themeName })
