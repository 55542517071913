module.exports = [{
      plugin: require('/builds/tridimensionalweb/reutilizable/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/tridimensionalweb/reutilizable/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"reutilizable","path":"/","previews":true,"pages":[{"type":"Pagina_legal","match":"/:uid","path":"/preview","component":"/builds/tridimensionalweb/reutilizable/src/templates/PageLegal.jsx"},{"type":"Pagina_de_producto","match":"/:uid","path":"/preview","component":"/builds/tridimensionalweb/reutilizable/src/templates/ProductCategory.jsx"}]},
    },{
      plugin: require('/builds/tridimensionalweb/reutilizable/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/builds/tridimensionalweb/reutilizable/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-138672892-1"},"environments":["production"]},
    },{
      plugin: require('/builds/tridimensionalweb/reutilizable/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
