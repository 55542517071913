import React from 'react'
import TopWrappedWeb from './src/utils/TopWrappedWeb'
import { registerLinkResolver } from 'gatsby-source-prismic-graphql'
import linkResolver from './src/utils/linkResolver'

registerLinkResolver(linkResolver)

export const wrapRootElement = ({ element }) => {
  return <TopWrappedWeb>{element}</TopWrappedWeb>
}
